export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '',
    banner_img_root : '',
    resid : '',
    key_value : 'tacosjalisco',
    secret_value : 'tacosjalisco',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12266.656225625564!2d-105.0431845!3d39.7696505!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x92e22f9a08b5dab1!2sTacos%20Jalisco!5e0!3m2!1sen!2sin!4v1672311003375!5m2!1sen!2sin'

};
